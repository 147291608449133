<script setup lang="ts">
import { Icon as NuxtIcon } from "#components";

import { useNuxtApp } from "#app/nuxt";
import { useLocalePath } from "#i18n";

defineProps({
  text: {
    type: String,
    default: "More",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { $auth } = useNuxtApp();

const accounts = await $auth.getAccounts();

const username = computed(() => {
  if (accounts.length < 1) return "";

  const acc = accounts[0];
  const accName = acc?.name ?? "";
  const accUsername = acc?.username;

  if (accName?.length > 0) return accName;

  if (accUsername?.length > 0) return accUsername;

  return "";
});

const avatarInitial = computed(() => {
  if (accounts.length < 1) return "U";

  const acc = accounts[0];
  const accName = acc?.name ?? "";
  const accUsername = acc?.username;

  if (accName?.length > 0) return accName[0];

  if (accUsername?.length > 0) return accUsername[0];

  return "U";
});

const showDropdown = ref(false);
const toggleDropdown = () => (showDropdown.value = !showDropdown.value);
const closeDropdown = () => (showDropdown.value = false);

const localePath = useLocalePath();

async function handleLogout() {
  await $auth.logout();
  const accounts = await $auth.getAccounts();
  if (accounts.length < 1) {
    await navigateTo(localePath("/login"));
  }
}
</script>
<template>
  <div class="relative">
    <button
      class="w-8 h-8 text-sm uppercase rounded-full bg-nred-3 inline-flex items-center justify-center text-white shadow shadow-ngray-2 hover:bg-opacity-50"
      :class="{
        'opacity-50 pointer-events-none grayscale': disabled,
      }"
      :disabled="disabled"
      @click="toggleDropdown"
    >
      <span>{{ avatarInitial }}</span>
    </button>
    <Transition name="fade" mode="out-in">
      <div
        v-if="showDropdown"
        v-click-outside="closeDropdown"
        class="absolute top-10 right-0 px-6 py-6 bg-white z-50 min-w-[100px] shadow-lg rounded"
      >
        <div class="flex flex-col gap-3">
          <div class="self-end text-sm text-gray-600">{{ username }}</div>
          <div class="flex justify-end">
            <button
              type="button"
              class="flex justify-end items-center gap-2 min-w-[90px] hover:opacity-60"
              @click="handleLogout"
            >
              <nuxt-icon name="fa6-solid:right-from-bracket" size="14px" class="text-ngray-1" />
              <span class="text-sm text-ngray-1">{{ $t("auth.logout") }}</span>
            </button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
