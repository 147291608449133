<script setup lang="ts">
import type { LocaleObject } from "@nuxtjs/i18n";

const { locale, locales, setLocale } = useI18n();
const router = useRouter();
const switchLocalePath = useSwitchLocalePath();

const showDropdown = ref(false);
const toggleDropdown = () => (showDropdown.value = !showDropdown.value);
const closeDropdown = () => (showDropdown.value = false);

function handleChange(newLang: string) {
  closeDropdown();
  setLocale(newLang);
  const newPath = switchLocalePath(newLang);
  router.push(newPath);
}
</script>
<template>
  <div class="relative">
    <button
      class="bg-transparent border-none inline-flex items-center gap-1 text-sm"
      type="button"
      @click="toggleDropdown"
    >
      <span class="capitalize">{{ locale }}</span>
      <base-icon name="mdi:globe" size="18px" class="text-black" />
      <base-icon name="fa6-solid:chevron-down" size="12px" class="text-black" />
    </button>
    <Transition name="fade" mode="out-in">
      <div
        v-if="showDropdown"
        v-click-outside="closeDropdown"
        class="absolute top-6 right-0 px-4 py-3 bg-white z-50 min-w-[150px] shadow-lg rounded"
      >
        <div v-for="lang in locales as LocaleObject[]" :key="lang.code" class="flex flex-col gap-4">
          <button
            class="flex items-center justify-between gap-1 hover:opacity-60 whitespace-nowrap"
            type="button"
            @click="() => handleChange(lang.code)"
          >
            <span class="text-base text-black capitalize">{{ lang.code }}</span>
            <div class="h-px bg-ngray-6 flex-1"></div>
            <span class="text-base text-black">{{ lang.name }}</span>
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>
